import React, { useState } from 'react';
import mediaKitCategoriesData from '../data/mediaKitCategoriesData';
import { FaChevronDown, FaChevronUp, FaFileDownload } from 'react-icons/fa'; // Importing the icons
import { PiFileZipBold } from 'react-icons/pi'; // Import PiFileZipBold icon
import { disclaimers } from '../data/disclaimerData'; // Importing disclaimers object
import { motion } from 'framer-motion';
import SEILogo from '../icons/SEI.png'; // Import SEI logo

const cardHoverVariants = {
  hover: {
    scale: 1.05,
    transition: {
      duration: 0.3,
      ease: 'easeInOut',
    },
  },
};

const MediaKits = () => {
  const [expandedSections, setExpandedSections] = useState({});

  const toggleSection = (sectionIndex) => {
    setExpandedSections(prevState => ({
      ...prevState,
      [sectionIndex]: !prevState[sectionIndex]
    }));
  };

  const toggleSubSection = (sectionIndex, subSectionIndex) => {
    setExpandedSections(prevState => ({
      ...prevState,
      [sectionIndex]: {
        ...prevState[sectionIndex],
        [subSectionIndex]: !prevState[sectionIndex]?.[subSectionIndex]
      }
    }));
  };

  return (
    <div className="flex flex-col items-center justify-center bg-gray-800 text-white p-4 pb-16">
      <h1 className="text-2xl md:text-4xl font-bold text-center text-white mb-6 flex items-center justify-center">
        <img src={SEILogo} alt="SEI Logo" className="w-8 h-8 mr-2" />
        Media Kits
      </h1>
      <p className="text-xl text-gray-400 text-center max-w-2xl mb-8">
        Information and resources available for media use.
      </p>

      <div className="w-full max-w-5xl">
        {Object.keys(mediaKitCategoriesData).map((category, index) => (
          <div key={index} className="mb-8">
            <div
              className="cursor-pointer mb-4 flex items-center justify-between"
              onClick={() => toggleSection(category)}
            >
              <h3 className="text-lg md:text-2xl font-bold">{category}</h3>
              {expandedSections[category] ? <FaChevronUp /> : <FaChevronDown />}
            </div>
            {expandedSections[category] && (
              <div>
                {typeof mediaKitCategoriesData[category] === 'object' && !Array.isArray(mediaKitCategoriesData[category])
                  ? Object.keys(mediaKitCategoriesData[category]).map((subCategory, subIndex) => (
                    <div key={subIndex} className="mb-4">
                      <div
                        className="cursor-pointer mb-2 flex items-center justify-between pl-4"
                        onClick={() => toggleSubSection(category, subCategory)}
                      >
                        <h4 className="text-md md:text-xl font-semibold">{subCategory}</h4>
                        {expandedSections[category]?.[subCategory] ? <FaChevronUp /> : <FaChevronDown />}
                      </div>
                      {expandedSections[category]?.[subCategory] && (
                        <div className="grid grid-cols-3 md:grid-cols-6 gap-4">
                          {mediaKitCategoriesData[category][subCategory].map((item, itemIndex) => (
                            <motion.div
                              key={itemIndex}
                              className="flex flex-col items-center space-y-2 p-4 bg-gray-900 rounded-lg shadow-lg"
                              whileHover="hover"
                              variants={cardHoverVariants}
                            >
                              <h5 className="text-sm md:text-base font-semibold">{item.name}</h5>
                              {/* Removed `rounded-full` to display logos in original shape */}
                              <img src={item.logo} alt={`${item.name} Logo`} className="w-12 h-12 md:w-16 md:h-16" />
                              <a href={item.link} download={item.name} className="text-blue-500 hover:underline text-xs md:text-sm flex items-center space-x-1">
                                <FaFileDownload aria-label="Download" />
                              </a>
                            </motion.div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))
                  : (
                    <div className="grid grid-cols-3 md:grid-cols-6 gap-4">
                      {mediaKitCategoriesData[category].map((item, itemIndex) => (
                        <motion.div
                          key={itemIndex}
                          className="flex flex-col items-center space-y-2 p-4 bg-gray-900 rounded-lg shadow-lg"
                          whileHover="hover"
                          variants={cardHoverVariants}
                        >
                          <h4 className="text-sm md:text-base font-semibold">{item.name}</h4>
                          {/* Removed `rounded-full` to display logos in original shape */}
                          <img src={item.logo} alt={`${item.name} Logo`} className="w-12 h-12 md:w-16 md:h-16" />
                          <a href={item.link} download={item.name} className="text-blue-500 hover:underline text-xs md:text-sm flex items-center space-x-1">
                            <FaFileDownload aria-label="Download" />
                          </a>
                        </motion.div>
                      ))}
                    </div>
                  )}
              </div>
            )}
          </div>
        ))}

        <div className="mt-6 text-center flex justify-center">
          <a href="icons.zip" download className="text-blue-500 hover:underline text-sm md:text-base flex items-center space-x-1">
            <PiFileZipBold aria-label="Download All as ZIP" /> <span>Download All</span>
          </a>
        </div>
        <div className="mt-4 bg-gray-900 p-4 rounded-lg shadow-lg text-xs md:text-sm text-center text-gray-300">
          <p>{disclaimers['default']}</p> {/* Using the default disclaimer text */}
        </div>
      </div>
    </div>
  );
};

export default MediaKits;
