import palletLogo from '../icons/Pallet.png';
import mrktLogo from '../icons/MRKT.png';
import frensei from '../icons/Frensei.png';
import meLogo from '../icons/Magic Eden.png';

export const nftData = [
    {
    name: "Frensei Launchpad",
    logo: frensei,
    description: "Frensei is a platform by On-Chain Orbiters on the Sei Network, offering tools and services for creating and launching NFT projects.",
    features: [
      'Launchpad: A scalable, application-based NFT launchpad with features like dynamic mint allocations and an allowlist manager.',
      'Coins: Create ERC-20 or Sei Factory tokens using a simple no-code experience.',
      'Funding: Received direct funding from Sei Foundation.'
    ],
    website: "https://www.frensei.io/",
    twitter: "https://x.com/orbitersnft",
  },
   {
    name: "Magic Eden",
    logo: meLogo,
    description: "Magic Eden is a leading NFT marketplace that empowers digital creators and collectors by offering a platform for discovery, expression, and ownership across digital cultures.",
    features: [
      'Launchpad: An exclusive minting platform that selectively accepts only top-tier projects, offering development support and exposure for creators.',
    ],
    website: "https://magiceden.io/",
    twitter: "https://x.com/MagicEden",
  },
  {
    name: "MRKT Exchange",
    logo: mrktLogo,
    description: "MRKT Exchange is a premier NFT marketplace with a 2% platform fee.",
    features: [
      'Leaderboards: Track and highlight top traders.',
	  'Launchpad Services: Successfully launched over 5 projects.',
      '404 Support: Provides native support for 404s.',
      'Built by the Seiyan NFT Team.',
    ],
    website: "https://mrkt.exchange",
    twitter: "https://x.com/mrkt_exchange",
  },
    {
    name: "Pallet Exchange",
    logo: palletLogo,
    description: "Pallet Exchange is the leading NFT marketplace on SEI with a 2% platform fee.",
    features: [
	  'Leaderboards: Track and highlight top traders.',
      'DegeNS Name Service: Fun and seasonal domain names for users.',
      'Airdrop Hints: Stay tuned for potential airdrops.',
      'Private Funding: Secured $2.5 million in private funding.',
      'Open API: Provides an open API that supports the entire ecosystem.'
    ],
    website: "https://pallet.exchange",
    twitter: "https://x.com/pallet_exchange",
  }
];
