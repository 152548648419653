import React, { useState, useEffect } from 'react';
import spacesData from '../data/spacesData';
import spacesFormData from '../data/SpacesFormData';
import { disclaimers } from '../data/disclaimerData';
import SEILogo from '../icons/SEI.png'; // Import SEI logo
import s3Logo from '../icons/S3.png';
import { motion } from 'framer-motion';
import { FaChevronDown, FaChevronUp, FaTwitter } from 'react-icons/fa'; // Import Twitter icon

const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const cardHoverVariants = {
  hover: {
    scale: 1.05,
    transition: {
      duration: 0.3,
      ease: 'easeInOut',
    },
  },
};

const convertTimeToNumber = (time) => {
  const [timePart, period] = time.split(' ');
  let [hours, minutes] = timePart.split(':').map(Number);

  if (period === 'PM' && hours !== 12) {
    hours += 12;
  } else if (period === 'AM' && hours === 12) {
    hours = 0;
  }

  return hours * 100 + minutes;
};

const getCurrentDayIndex = () => {
  const today = new Date().getDay();
  return today === 0 ? 6 : today - 1;
};

// Main Navigation Bar Component
const MainNavBar = () => {
  return (
    <div className="fixed top-0 left-0 right-0 bg-black text-white py-4 px-8 z-30">
      <h1 className="text-2xl font-bold">Main Navigation Bar</h1>
    </div>
  );
};

const Spaces = () => {
  const currentDayIndex = getCurrentDayIndex();
  const [expandedDay, setExpandedDay] = useState(daysOfWeek[currentDayIndex]);

  const orderedDaysOfWeek = [
    ...daysOfWeek.slice(currentDayIndex),
    ...daysOfWeek.slice(0, currentDayIndex),
  ];

  const [formVisible, setFormVisible] = useState(false);
  const [formData, setFormData] = useState(
    spacesFormData.reduce((acc, field) => ({ ...acc, [field.name]: field.defaultValue || '' }), {})
  );

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setFormData({
        ...formData,
        [name]: checked ? [...formData[name], value] : formData[name].filter((day) => day !== value),
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const codeFormatted = `{
      days: ${JSON.stringify(formData.days)},
      cadence: '${formData.cadence}',
      NameofSpace: '${formData.NameofSpace}',
      timeUTC: '${formData.timeUTC}',
      twitter: '${formData.twitter}',
      hostsUsernames: ${JSON.stringify(formData.hostsUsernames.split(',').map(host => host.trim()))},
      spacesLogo: s3Logo,
      description: '${formData.description}',
    }`;

    const payload = {
      content: `New Space Request:\n\n**Code Format:**\n\`\`\`javascript\n${codeFormatted}\n\`\`\`\n\n**Formatted:**\n**Days:** ${formData.days.join(', ')}\n**Cadence:** ${formData.cadence}\n**Name of Space:** ${formData.NameofSpace}\n**Time (UTC):** ${formData.timeUTC}\n**Twitter:** ${formData.twitter}\n**Hosts:** ${formData.hostsUsernames}\n**Description:** ${formData.description}`,
    };

    try {
      await fetch('https://discord.com/api/webhooks/1278808230838927453/rp9doaGRAxe4R0wfW7BwU9FeHj0QpaPkmnoh6ayXH6FKMxpsZJGS1CAJT5C36GEAzw0p', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
      alert('Request submitted successfully!');
      setFormVisible(false);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } catch (error) {
      console.error('Error submitting the request:', error);
      alert('Failed to submit the request.');
    }
  };

  const toggleSection = (day) => {
    setExpandedDay((prevDay) => (prevDay === day ? null : day));
  };

  // Scroll to top when a new day is selected
  useEffect(() => {
    if (expandedDay) {
      document.getElementById('spaces-container')?.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [expandedDay]);

  return (
    <div className="flex flex-col items-center justify-center text-white p-4 pb-16">
      <MainNavBar />

{/* Days of the Week */}
<div
  className="fixed top-16 left-0 right-0 w-full flex flex-wrap justify-center py-4 z-20"
  style={{ backgroundColor: '#111827' }}
>
  {orderedDaysOfWeek.map((day) => (
    <div
      key={day}
      className={`cursor-pointer text-sm md:text-lg font-semibold px-4 py-2 flex items-center text-white mx-2 ${expandedDay === day ? 'bg-blue-700 rounded-lg' : ''}`}
      onClick={() => toggleSection(day)}
    >
      {/* Show abbreviated names on small screens, full names on medium and above */}
      <span className="block md:hidden">{day.slice(0, 3)}</span> {/* Mobile: Sun, Mon, etc. */}
      <span className="hidden md:block">{day}</span> {/* Desktop: Sunday, Monday, etc. */}
      {expandedDay === day ? <FaChevronUp className="ml-1" /> : <FaChevronDown className="ml-1" />}
    </div>
  ))}
</div>





{/* Twitter Spaces Display */}
<div id="spaces-container" className="w-full max-w-5xl mt-16 md:mt-10"> {/* Increased margin-top on mobile */} {/* Adjusted margin-top for consistent spacing */}
  {orderedDaysOfWeek.map((day) => {
    const filteredSpaces = spacesData
      .filter((space) => space.days && space.days.includes(day))
      .sort((a, b) => convertTimeToNumber(a.timeUTC) - convertTimeToNumber(b.timeUTC));

    return (
      <div key={day} id={day} className={`mb-8 ${expandedDay === day ? 'mt-6' : ''}`}> {/* Decreased margin-top when switching days */}
        {expandedDay === day && (
          <>
            {/* Centered Header for SEI Twitter Spaces */}
            <div className="flex items-center justify-center mb-6">
              <img src={SEILogo} alt="SEI Logo" className="w-8 h-8 mr-2" /> {/* SEI Logo */}
              <h2 className="text-2xl md:text-3xl font-bold text-white">Twitter Spaces</h2>
            </div>

            <div className="space-y-6 mt-6"> {/* Apply consistent margin between sets of cards */}
              {filteredSpaces.map((space, index) => (
                <motion.div
                  key={index}
                  className="w-full flex flex-row items-center mb-6 p-6 bg-gray-900 rounded-lg shadow-lg"
                  whileHover="hover"
                  variants={cardHoverVariants}
                >
                  {/* Icon Section */}
                  <div className="flex-shrink-0 mr-4">
                    <a href={space.twitter} target="_blank" rel="noopener noreferrer">
                      <img
                        src={space.spacesLogo}
                        alt={`${space.NameofSpace} Logo`}
                        className="w-18 h-18 md:w-20 md:h-20 rounded-full"
                        style={{ width: '75px', height: '75px' }}
                      />
                    </a>
                  </div>






{/* Text Section */}
<div className="flex-grow">
  {/* Space Name with Twitter Icon */}
  <div className="flex items-center mb-2">
    <h3 className="text-lg md:text-xl font-bold">{space.NameofSpace}</h3>
    <a
      href={space.twitter}
      target="_blank"
      rel="noopener noreferrer"
      className="ml-2 text-blue-500 hover:underline text-sm md:text-base flex items-center"
    >
      <FaTwitter size={18} /> {/* Twitter Icon next to Space Name */}
    </a>
  </div>
  
  <p className="text-sm md:text-base text-gray-400 italic mb-4">{space.description}</p>
  <p className="text-sm md:text-base text-gray-400 mb-2">
    <span className="font-semibold text-white">Time:</span> {space.timeUTC}
  </p>
  <p className="text-sm md:text-base text-gray-400 mb-4">
    <span className="font-semibold text-white">
      {space.hostsUsernames.length > 1 ? 'Hosts' : 'Host'}:
    </span>{' '}
    {space.hostsUsernames.map((host, index) => (
      <span key={index}>
        <a
          href={`https://x.com/${host.replace('@', '')}`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 hover:underline"
        >
          {host}
        </a>
        {index < space.hostsUsernames.length - 1 && ', '}
      </span>
    ))}
  </p>
</div>











                </motion.div>
              ))}
            </div>
          </>
        )}
      </div>
    );
  })}
</div>



      {/* Submit a Space Request Button and Form at the Bottom */}
      <div className="w-full max-w-5xl mt- flex flex-col items-center">
        <button
          onClick={() => {
            setFormVisible(!formVisible);
            if (!formVisible) window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          className="text-white bg-blue-600 px-6 py-3 rounded-md focus:outline-none hover:bg-blue-700 mb-6"
        >
          {formVisible ? 'Hide Request Form' : 'Request Your Spaces'}
        </button>
        {formVisible && (
          <form onSubmit={handleSubmit} className="mt-6 p-6 bg-gray-900 rounded-lg shadow-lg w-full">
            {/* Days of the Week Checkboxes */}
            <div className="mb-6">
              <label className="block text-sm md:text-base font-semibold mb-4">Days of the Week:</label>
              {daysOfWeek.map((day) => (
                <label key={day} className="block text-sm md:text-base text-gray-400 mb-2">
                  <input
                    type="checkbox"
                    name="days"
                    value={day}
                    checked={formData.days.includes(day)}
                    onChange={handleInputChange}
                    className="mr-2"
                  />
                  {day}
                </label>
              ))}
            </div>

            {/* Cadence Radio Buttons */}
            <div className="mb-6">
              <label className="block text-sm md:text-base font-semibold mb-4">Cadence:</label>
              {['Daily', 'Weekly', 'Bi-weekly', 'Monthly'].map((cadence) => (
                <label key={cadence} className="block text-sm md:text-base text-gray-400 mb-2">
                  <input
                    type="radio"
                    name="cadence"
                    value={cadence}
                    checked={formData.cadence === cadence}
                    onChange={handleInputChange}
                    className="mr-2"
                  />
                  {cadence}
                </label>
              ))}
            </div>

            {spacesFormData.filter(field => field.type !== 'checkbox' && field.type !== 'radio').map((field, index) => (
              <div className="mb-6" key={index}>
                <label className="block text-sm md:text-base font-semibold mb-4">{field.label}:</label>
                {field.type === 'textarea' ? (
                  <textarea
                    name={field.name}
                    value={formData[field.name]}
                    onChange={handleInputChange}
                    placeholder={field.placeholder}
                    className="w-full p-3 rounded-md bg-gray-800 text-white"
                    rows="3"
                  ></textarea>
                ) : (
                  <input
                    type={field.type}
                    name={field.name}
                    value={formData[field.name]}
                    onChange={handleInputChange}
                    placeholder={field.placeholder}
                    className="w-full p-3 rounded-md bg-gray-800 text-white"
                  />
                )}
              </div>
            ))}
            <button type="submit" className="text-white bg-blue-600 px-6 py-3 rounded-md focus:outline-none hover:bg-blue-700 mt-4">
              Submit Request
            </button>
          </form>
        )}
      </div>

      {/* Disclaimer Section */}
      <div className="w-full max-w-5xl mt-8 bg-gray-900 p-6 rounded-lg shadow-lg text-xs md:text-sm text-center text-gray-300">
        <p>{disclaimers.spaces}</p>
      </div>
    </div>
  );
};

export default Spaces;
