import seiScanLogo from '../icons/SeiScan.png';
import seiTraceLogo from '../icons/Seitrace.png';

export const explorersData = [
  {
    name: "Sei Scan",
    logo: seiScanLogo,
    description: "A specialized blockchain explorer for the Sei network's native side, allowing users to track transactions, explore blocks, and access detailed data specific to the Sei native chain within the parallelized blockchain ecosystem.",
    link: "https://seiscan.app/",
    twitter: "https://twitter.com/SeiScan",
    docs: "https://docs.alleslabs.com/user-guide/introduction/overview",
    websiteLinkText: "Website"
  },
  {
    name: "Sei Trace",
    logo: seiTraceLogo,
    description: "A powerful tracing tool designed for the Sei network's EVM side, providing comprehensive analysis and tracking of transactions and events to enhance transparency and performance within the EVM environment of the parallel blockchain.",
    link: "https://seitrace.com/",
    twitter: "https://twitter.com/SeiTrace",
    docs: "https://docs.seitrace.com/",
    websiteLinkText: "Website"
  },
];
