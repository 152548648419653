// src/components/meme.js
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FaTwitter, FaClipboard, FaCheck } from 'react-icons/fa';
import memeData from '../data/memedata'; // Importing the meme data
import { disclaimers } from '../data/disclaimerData'; // Importing disclaimers
import DexScreenerIcon from '../icons/DEX Screener.png'; // Importing DEX Screener icon
import CoinGeckoIcon from '../icons/Coingecko.png'; // Importing CoinGecko icon
import DragonSwapIcon from '../icons/Dragon Swap.png';
import SEILogo from '../icons/SEI.png'; // Import SEI logo

const cardHoverVariants = {
  hover: {
    scale: 1.05,
    transition: {
      duration: 0.3,
      ease: 'easeInOut',
    },
  },
};

const truncateAddress = (address) => {
  if (window.innerWidth < 768) { // Mobile screen size
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  }
  return address;
};

const MemeCoinCard = ({ logo, name, description, twitter, dexScreener, dragonSwap, coinGecko, contractAddress }) => {
  const [copied, setCopied] = useState(false);
  const [truncatedAddress, setTruncatedAddress] = useState(contractAddress);

  useEffect(() => {
    const handleResize = () => setTruncatedAddress(truncateAddress(contractAddress));

    handleResize(); // Initial call to set the address based on the current screen width
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize); // Cleanup listener on component unmount
  }, [contractAddress]);

  const handleCopy = () => {
    navigator.clipboard.writeText(contractAddress)
      .then(() => setCopied(true))
      .catch(err => console.error('Failed to copy:', err))
      .finally(() => setTimeout(() => setCopied(false), 2000)); // Reset state after 2 seconds
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 50, scale: 0.9 }}
      animate={{ opacity: 1, y: 0, scale: 1 }}
      exit={{ opacity: 0, y: -50, scale: 0.9 }}
      whileHover="hover"
      variants={cardHoverVariants}
      className="bg-gray-900 p-4 md:p-6 rounded-lg shadow-lg flex flex-col items-center space-y-4 mb-6 w-full max-w-sm md:max-w-2xl mx-auto"
    >
      <img src={logo} alt={`${name} Logo`} className="w-16 h-16 md:w-24 md:h-24 rounded-full" />
      <div className="text-left w-full">
        <h3 className="text-lg md:text-2xl font-bold mb-2 flex items-center">
          {name}
          <a href={twitter} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline ml-2">
            <FaTwitter className="w-5 h-5" />
          </a>
        </h3>
        <p className="text-sm md:text-gray-400 mb-2">{description}</p>
        <div className="space-y-2">
          <a href={coinGecko} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline text-sm md:text-base flex items-center">
            <img src={CoinGeckoIcon} alt="CoinGecko" className="w-4 h-4 mr-2" />
            CoinGecko
          </a>
          <a href={dexScreener} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline text-sm md:text-base flex items-center">
            <img src={DexScreenerIcon} alt="DEX Screener" className="w-4 h-4 mr-2" />
            DEX Screener
          </a>
          <a href={dragonSwap} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline text-sm md:text-base flex items-center">
            <img src={DragonSwapIcon} alt="DragonSwap" className="w-4 h-4 mr-2" />
            DragonSwap
          </a>
          <div className="text-sm md:text-base text-center">
            <p className="mb-2">Contract Address:</p>
            <div className="flex items-center justify-center space-x-2">
              <a
                href={`https://seitrace.com/address/${contractAddress}`} // Updated URL
                target="_blank"
                rel="noopener noreferrer"
                className="font-mono bg-gray-800 p-2 rounded-md text-white hover:underline"
              >
                {truncatedAddress}
              </a>
              <button
                onClick={handleCopy}
                className="bg-gray-700 hover:bg-gray-600 text-white p-2 rounded-md flex items-center"
              >
                {copied ? <FaCheck className="w-5 h-5" /> : <FaClipboard className="w-5 h-5" />}
              </button>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const Meme = () => {
  return (
    <div className="flex flex-col items-center justify-center text-white p-4 pb-16">
      <h1 className="text-2xl md:text-4xl font-bold text-center text-white mb-6 flex items-center justify-center">
        <img src={SEILogo} alt="SEI Logo" className="w-8 h-8 mr-2" /> Meme Coins
      </h1>
      <div className="hidden md:grid w-full max-w-5xl grid-cols-1 md:grid-cols-2 gap-6 mt-6">
        {memeData.map((coin, index) => (
          <MemeCoinCard key={index} {...coin} />
        ))}
      </div>
      <div className="md:hidden w-full">
        {memeData.map((coin, index) => (
          <MemeCoinCard key={index} {...coin} />
        ))}
      </div>

      {/* Disclaimer */}
      <div className="mt-6 bg-gray-900 p-4 rounded-lg shadow-lg w-full max-w-5xl mx-auto text-xs md:text-sm text-center text-gray-300">
        <p>{disclaimers.memeCoins}</p> {/* Using the disclaimer text from disclaimerData */}
      </div>
    </div>
  );
};

export default Meme;
