// src/data/NFTCommDataForm.js

const NFTCommDataForm = [
  {
    name: 'website',
    label: 'Website',
    type: 'text',
    placeholder: 'e.g., https://example.com',
    defaultValue: '',
  },
  {
    name: 'twitter',
    label: 'Twitter',
    type: 'text',
    placeholder: 'e.g., https://twitter.com/yourhandle',
    defaultValue: '',
  },
  {
    name: 'discordName',
    label: 'Discord Name',
    type: 'text',
    placeholder: 'e.g., YourDiscord#1234',
    defaultValue: '',
  },
  {
    name: 'description',
    label: 'Description',
    type: 'textarea',
    placeholder: 'Describe your community...',
    defaultValue: '',
  },
  {
    name: 'feature1',
    label: 'Feature 1',
    type: 'text',
    placeholder: 'Feature that describes your community...',
    defaultValue: '',
  },
  {
    name: 'feature2',
    label: 'Feature 2',
    type: 'text',
    placeholder: 'Another feature that describes your community...',
    defaultValue: '',
  },
  {
    name: 'feature3',
    label: 'Feature 3',
    type: 'text',
    placeholder: 'Yet another feature that describes your community...',
    defaultValue: '',
  },
];

export default NFTCommDataForm;
