// src/data/SpacesFormData.js

const spacesFormData = [
  {
    name: 'days',
    label: 'Days of the Week',
    type: 'checkbox',
    options: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    defaultValue: [],
  },
  {
    name: 'cadence',
    label: 'Cadence',
    type: 'radio',
    options: ['Daily', 'Weekly', 'Bi-weekly', 'Monthly'],
    defaultValue: 'Weekly',
  },
  {
    name: 'NameofSpace',
    label: 'Name of Space',
    type: 'text',
    placeholder: 'e.g., Layers, w/ Grilled Onion',
    defaultValue: '',
  },
  {
    name: 'timeUTC',
    label: 'Time (UTC)',
    type: 'text',
    placeholder: 'e.g., 10:00 PM UTC',
    defaultValue: '',
  },
  {
    name: 'twitter',
    label: 'Twitter Link',
    type: 'text',
    placeholder: 'e.g., https://x.com/Grilled_Onion_',
    defaultValue: '',
  },
  {
    name: 'hostsUsernames',
    label: 'Hosts Usernames',
    type: 'text',
    placeholder: 'e.g., @Grilled_Onion_',
    defaultValue: '',
  },
  {
    name: 'description',
    label: 'Description',
    type: 'textarea',
    placeholder: 'Describe the Space',
    defaultValue: '',
  },
];

export default spacesFormData;
