// src/data/axiosCodeData.js

const axiosCodeData = {
  "Axios Specific Code": [
    {
      title: "Axios Example for Querying Token Info",
      description: "Example code using Axios to query data from a Sei blockchain smart contract.",
      code: `
const axios = require('axios');

const lcdURL = 'https://sei-api.polkachu.com';
const contractAddress = "sei1c7tj004asc9n6zhkk950u0nvxewwswanvg95aqazqs29q3l6nq9sqtrtwl";
const queryMsg = {
  "all_nft_info": {
    "token_id": "420"
  }
};

const queryContract = async () => {
  const queryB64Encoded = Buffer.from(JSON.stringify(queryMsg)).toString('base64');
  const res = await axios.get(\`\${lcdURL}/cosmwasm/wasm/v1/contract/\${contractAddress}/smart/\${queryB64Encoded}\`);
  console.log(res.data);
};

queryContract();
`
    }
  ]
};

export default axiosCodeData;
