import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FaTwitter, FaGlobe, FaDiscord } from 'react-icons/fa'; // Importing the icons
import { communitiesData } from '../data/communitiesData';
import NFTCommDataForm from '../data/NFTCommDataForm'; // Importing the form data
import { disclaimers } from '../data/disclaimerData'; // Importing disclaimers
import SEILogo from '../icons/SEI.png'; // Import SEI logo

const cardHoverVariants = {
  hover: {
    scale: 1.05,
    transition: {
      duration: 0.3,
      ease: 'easeInOut',
    },
  },
};

const CommunityCard = ({ logo, name, description, features, website, twitter, discord }) => (
  <motion.div
    initial={{ opacity: 0, y: 50, scale: 0.9 }}
    animate={{ opacity: 1, y: 0, scale: 1 }}
    exit={{ opacity: 0, y: -50, scale: 0.9 }}
    whileHover="hover"
    variants={cardHoverVariants}
    className="bg-gray-900 p-4 md:p-6 rounded-lg shadow-lg flex flex-col items-center space-y-4 mb-6 w-full max-w-sm md:max-w-2xl mx-auto"
  >
    <img src={logo} alt={`${name} Logo`} className="w-16 h-16 md:w-24 md:h-24 rounded-full" />
    <div className="text-left w-full">
      <h3 className="text-lg md:text-2xl font-bold mb-2">{name}</h3>
      <p className="text-sm md:text-gray-400 mb-2">{description}</p>
      <ul className="list-disc list-inside text-sm md:text-gray-400 mb-2 space-y-1">
        {features.map((feature, index) => (
          <li key={index} className="whitespace-normal">{feature}</li>
        ))}
      </ul>
      <div className="space-x-4 mt-4 flex items-center">
        {/* Updated Links with Icons */}
        {website && (
          <a href={website} target="_blank" rel="noopener noreferrer" aria-label="Website">
            <FaGlobe className="text-blue-500 hover:text-blue-700" size={20} />
          </a>
        )}
        {twitter && (
          <a href={twitter} target="_blank" rel="noopener noreferrer" aria-label="Twitter">
            <FaTwitter className="text-blue-500 hover:text-blue-700" size={20} />
          </a>
        )}
        {discord && (
          <a href={discord} target="_blank" rel="noopener noreferrer" aria-label="Discord">
            <FaDiscord className="text-blue-500 hover:text-blue-700" size={20} />
          </a>
        )}
      </div>
    </div>
  </motion.div>
);

const Community = () => {
  const [formVisible, setFormVisible] = useState(false); // State to control form visibility
  const [formData, setFormData] = useState(
    NFTCommDataForm.reduce((acc, field) => ({ ...acc, [field.name]: field.defaultValue || '' }), {})
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const codeFormatted = `{
      website: '${formData.website}',
      twitter: '${formData.twitter}',
      discordName: '${formData.discordName}',
      description: '${formData.description}',
      features: ['${formData.feature1}', '${formData.feature2}', '${formData.feature3}'],
    }`;

    const payload = {
      content: `New NFT Community Submission:\n\n**Code Format:**\n\`\`\`javascript\n${codeFormatted}\n\`\`\`\n\n**Formatted:**\n**Website:** ${formData.website}\n**Twitter:** ${formData.twitter}\n**Discord Name:** ${formData.discordName}\n**Description:** ${formData.description}\n**Features:** ${formData.feature1}, ${formData.feature2}, ${formData.feature3}`,
    };

    try {
      await fetch('https://discord.com/api/webhooks/1278808230838927453/rp9doaGRAxe4R0wfW7BwU9FeHj0QpaPkmnoh6ayXH6FKMxpsZJGS1CAJT5C36GEAzw0p', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
      alert('Submission successful!');
      setFormVisible(false);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } catch (error) {
      console.error('Error submitting the request:', error);
      alert('Failed to submit the request.');
    }
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center text-white p-4 pb-16">
        {/* Updated Header with SEI Icon */}
        <h1 className="text-2xl md:text-4xl font-bold text-center text-white mb-6 flex items-center justify-center">
          <img src={SEILogo} alt="SEI Logo" className="w-8 h-8 mr-2" />
          NFT Communities
        </h1>
        <div className="hidden md:grid w-full max-w-5xl grid-cols-1 md:grid-cols-2 gap-6 mt-6">
          {communitiesData.map((community, index) => (
            <CommunityCard key={index} {...community} />
          ))}
        </div>
        <div className="md:hidden w-full">
          {communitiesData.map((community, index) => (
            <CommunityCard key={index} {...community} />
          ))}
        </div>

        <div className="mt-6 bg-gray-900 p-4 rounded-lg shadow-lg w-full max-w-5xl mx-auto text-xs md:text-sm text-center text-gray-300">
          <p>{disclaimers.nftCommunities}</p> {/* Using the disclaimer text from disclaimerData */}
        </div>
      </div>
    </>
  );
};

export default Community;
