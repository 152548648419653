// src/data/seiscanCodeData.js

const seiscanCodeData = {
  "SeiScan Queries": [
    {
      title: "Query Token ID Info on SeiScan",
      description: "You can query token ID information from the contract address  directly on SeiScan using the following JSON query.",
      code: `
{
  "all_nft_info": {
    "token_id": "420"
  }
}
`,
      link: "https://www.seiscan.app/pacific-1/interact-contract?selectedType=query&contract=sei1c7tj004asc9n6zhkk950u0nvxewwswanvg95aqazqs29q3l6nq9sqtrtwl&msg=ewogICJhbGxfbmZ0X2luZm8iOiB7fQp9", // Added link to SeiScan URL
      linkText: "View on SeiScan", // Text for the link button
    }
  ],
};

export default seiscanCodeData;
