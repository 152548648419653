import SeiyanLogo from '../icons/Seiyan.png';
import GonadLogo from '../icons/Gonad.png';
import whaleLogo from '../icons/Sei Whale.png';

const memeData = [
  {
    name: '$SEIYAN',
	contractAddress: '0x5f0E07dFeE5832Faa00c63F2D33A0D79150E8598',
    description: 'The first meme coin on the Sei network!',
    logo: SeiyanLogo,
    twitter: 'https://x.com/seiyantoken9000', // Replace with actual Twitter link
    dexScreener: 'https://dexscreener.com/seiv2/0x8ba502d9243fa5e44678044098be9782cebafda5', // Replace with actual DexScreener link
    dragonSwap: 'https://dragonswap.app/tokens/0x5f0E07dFeE5832Faa00c63F2D33A0D79150E8598?searchQuery=&timeSlot=hour', // Replace with actual DragonSwap link
    coinGecko: 'https://www.coingecko.com/en/coins/seiyan', // Replace with actual CoinGecko link
  },
  {
    name: '$GONAD',
	contractAddress: '0xd78BE621436e69C81E4d0e9f29bE14C5EC51E6Ae',
    description: 'A reproductive gland.',
    logo: GonadLogo,
    twitter: 'https://x.com/gonadGND', // Replace with actual Twitter link
    dexScreener: 'https://dexscreener.com/seiv2/0xbe3b8eb9ae801395040b013b73aa539f46a9c6a8', // Replace with actual DexScreener link
    dragonSwap: 'https://dragonswap.app/tokens/0xd78BE621436e69C81E4d0e9f29bE14C5EC51E6Ae?searchQuery=&timeSlot=hour', // Replace with actual DragonSwap link
    coinGecko: 'https://www.geckoterminal.com/sei-evm/pools/0xbe3b8eb9ae801395040b013b73aa539f46a9c6a8?utm_source=coingecko&utm_medium=referral&utm_campaign=searchresults',},
	 {
    name: 'SEI Whale',
	contractAddress: '0x67DA7010C6c231AD620E3940e707adB6c1a08f23',
    description: 'A fun and unique meme coin with a strong community!',
    logo: whaleLogo,
    twitter: 'https://x.com/seithewhale',
    dexScreener: 'https://dexscreener.com/seiv2/0x67DA7010C6c231AD620E3940e707adB6c1a08f23',
    dragonSwap: 'https://dragonswap.app/tokens/0x67da7010c6c231ad620e3940e707adb6c1a08f23?searchQuery=&timeSlot=hour', 
    coinGecko: 'https://www.coingecko.com/en/coins/seiwhale',}
];

export default memeData;
