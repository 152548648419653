import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { exchangeData } from '../data/exchangeData';
import SEILogo from '../icons/SEI.png'; // Import SEI logo
import { FaCopy, FaCheck } from 'react-icons/fa'; // Import copy and check icons
import axios from 'axios'; // Ensure axios is imported

const cardHoverVariants = {
  hover: {
    scale: 1.05,
    transition: {
      duration: 0.3,
      ease: 'easeInOut',
    },
  },
};

const formatNumber = (number) => {
  return number.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
};

// Function to get the SEI price in USD
const getCurrentSeiPrice = async () => {
  try {
    const response = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=sei-network&vs_currencies=usd');
    return parseFloat(response.data['sei-network'].usd);
  } catch (error) {
    console.error('Error fetching SEI price:', error);
    return null;
  }
};

// Function to get SEI balance for a single address
const getSeiBalance = async (address) => {
  try {
    const response = await axios.get(
      `https://celatone-api-prod.alleslabs.dev/v1/sei/pacific-1/accounts/${address}/balances`
    );
    const seiBalance = response.data.find((balance) => balance.denom === 'usei');
    return seiBalance ? parseFloat(seiBalance.amount) / 1e6 : 0; // Convert from micro to standard SEI
  } catch (error) {
    console.error('Error fetching SEI balance:', error);
    return 0;
  }
};

const ExchangeCard = ({ logo, name, url, walletAddresses, seiPrice }) => {
  const [copiedIndex, setCopiedIndex] = useState(null); // State to track which address is copied
  const [combinedBalance, setCombinedBalance] = useState(0);

  const handleCopy = (text, index) => {
    navigator.clipboard.writeText(text);
    setCopiedIndex(index); // Set the copied index to show the checkmark
    setTimeout(() => setCopiedIndex(null), 2000); // Reset after 2 seconds
  };

  useEffect(() => {
    const fetchCombinedBalance = async () => {
      let totalBalance = 0;
      for (const { address } of walletAddresses) {
        const balance = await getSeiBalance(address);
        totalBalance += balance;
      }
      setCombinedBalance(totalBalance);
    };

    fetchCombinedBalance();
  }, [walletAddresses]);

  return (
    <motion.div
      whileHover="hover"
      variants={cardHoverVariants}
      className="bg-gray-900 p-4 rounded-lg shadow-lg flex flex-col items-center justify-center text-center space-y-4 mb-4 w-full h-full"
    >
      <a href={url} target="_blank" rel="noopener noreferrer" className="w-full">
        <img src={logo} alt={`${name} Logo`} className="w-12 h-12 md:w-16 md:h-16 mx-auto" />
        <h3 className="text-sm md:text-base font-bold text-white mt-2">{name}</h3>
      </a>

      {/* Display combined SEI and USD balances */}
      {seiPrice !== null && (
        <div className="text-sm md:text-base text-white mt-2">
          <p>
            {walletAddresses.length > 1 ? 'Balances' : 'Balance'}: {formatNumber(combinedBalance)} SEI
          </p>
          <p>USD Value: ${formatNumber(combinedBalance * seiPrice)}</p>
        </div>
      )}

      {/* Wallet Addresses Section */}
      <div className="text-center w-full space-y-2">
        {walletAddresses.map(({ address, memoRequired, evmSupported }, index) => (
          <div key={index} className="flex flex-col items-center bg-gray-800 p-2 rounded mt-2">
            <div className="flex justify-center items-center w-full space-x-2">
              <a
                href={`https://seitrace.com/address/${address}`}
                target="_blank"
                rel="noopener noreferrer"
                className="font-mono bg-gray-800 p-2 rounded-md text-white text-xs md:text-sm hover:underline break-all"
              >
                {`${address.slice(0, 6)}...${address.slice(-8)}`}
              </a>
              <button
                onClick={() => handleCopy(address, index)}
                className="bg-gray-700 hover:bg-gray-600 text-white p-2 rounded-md flex items-center"
              >
                {copiedIndex === index ? <FaCheck className="w-5 h-5" /> : <FaCopy className="w-5 h-5" />}
              </button>
            </div>
            {/* Display "Memo Required" text if memoRequired is true */}
            {memoRequired && (
              <span className="text-xs md:text-sm text-red-500 mt-1">Memo Required!</span>
            )}
            {/* Display "EVM Supported" if evmSupported is true */}
            {evmSupported && (
              <span className="text-xs md:text-sm text-green-500 mt-1">EVM Supported</span>
            )}
          </div>
        ))}
      </div>
    </motion.div>
  );
};

const Exchanges = () => {
  const [seiPrice, setSeiPrice] = useState(null);

  useEffect(() => {
    const fetchPrice = async () => {
      const price = await getCurrentSeiPrice();
      setSeiPrice(price);
    };
    fetchPrice();
  }, []);

  return (
    <div className="flex flex-col items-center justify-center text-white p-4 pb-16 overflow-auto">
      <h1 className="text-2xl md:text-4xl font-bold text-center text-white mb-6 flex items-center justify-center">
        <img src={SEILogo} alt="SEI Logo" className="w-8 h-8 mr-2" />
        Exchanges
      </h1>
      {/* Desktop and Tablet View */}
      <div className="hidden md:grid w-full max-w-4xl grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-6">
        {exchangeData.map((exchange, index) => (
          <ExchangeCard key={index} {...exchange} seiPrice={seiPrice} />
        ))}
      </div>

      {/* Mobile View */}
      <div className="md:hidden w-full grid grid-cols-1 gap-4 mt-6">
        {/* Updated grid to single column on mobile */}
        {exchangeData.map((exchange, index) => (
          <ExchangeCard key={index} {...exchange} seiPrice={seiPrice} />
        ))}
      </div>
    </div>
  );
};

export default Exchanges;
