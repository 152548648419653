// src/components/NewToSEI.js
import React from 'react';

const NewToSEI = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-800 text-white">
      <h2 className="text-3xl font-bold mb-4">New to Sei?</h2>
      <p className="text-xl text-gray-400 text-center max-w-2xl">
        Welcome to the Sei network! Whether you're just getting started or looking to deepen your knowledge, we've got you covered. 
        Comprehensive guides for wallets, exchanges, DEXs, and coding are coming soon. Stay tuned as we prepare to help you navigate 
        and thrive in the Sei ecosystem.
      </p>
    </div>
  );
};

export default NewToSEI;
