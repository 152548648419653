import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp, FaClipboard, FaCheck } from 'react-icons/fa';
import { motion } from 'framer-motion';
import SEILogo from '../icons/SEI.png';
import { disclaimers } from '../data/disclaimerData';

// Importing data files
import devDocumentationData from '../data/devDocumentationData';
import seiscanCodeData from '../data/seiscanCodeData';
import axiosCodeData from '../data/axiosCodeData';

const DevStuff = () => {
  const [expandedSections, setExpandedSections] = useState({});
  const [copied, setCopied] = useState({});

  const toggleSection = (sectionIndex) => {
    setExpandedSections((prevState) => ({
      ...prevState,
      [sectionIndex]: !prevState[sectionIndex],
    }));
  };

  const handleCopy = (code, sectionIndex, itemIndex) => {
    navigator.clipboard.writeText(code);
    setCopied({ ...copied, [`${sectionIndex}-${itemIndex}`]: true });
    setTimeout(() => setCopied({ ...copied, [`${sectionIndex}-${itemIndex}`]: false }), 2000);
  };

  const renderSection = (sectionTitle, data, isFullWidth = false) => (
    <div className="mb-8">
      <div
        className="cursor-pointer mb-4 flex items-center justify-between"
        onClick={() => toggleSection(sectionTitle)}
      >
        <h3 className="text-lg md:text-2xl font-bold">{sectionTitle}</h3>
        {expandedSections[sectionTitle] ? <FaChevronUp /> : <FaChevronDown />}
      </div>
      {expandedSections[sectionTitle] && (
        <div className={`${isFullWidth ? 'grid grid-cols-1' : 'grid grid-cols-1 md:grid-cols-2'} gap-6`}>
          {data.map((item, itemIndex) => (
            <div
              key={itemIndex}
              className={`flex flex-col items-center space-y-2 p-4 bg-gray-900 rounded-lg shadow-lg ${isFullWidth ? 'w-full' : ''}`}
            >
              {item.logo && (
                <img src={item.logo} alt={`${item.name} Logo`} className="w-12 h-12 mb-2" />
              )}
              <h4 className="text-sm md:text-base font-semibold">{item.title || item.name}</h4>
              {item.code ? (
                <div className="relative w-full">
                  <pre className="bg-gray-800 p-2 rounded text-xs md:text-sm overflow-x-auto max-w-full">
                    <code className="whitespace-pre-wrap">{item.code.trim()}</code>
                  </pre>
                  <button
                    className="absolute top-2 right-2 bg-blue-600 text-white text-xs px-2 py-1 rounded hover:bg-blue-700 flex items-center"
                    onClick={() => handleCopy(item.code.trim(), sectionTitle, itemIndex)}
                  >
                    {copied[`${sectionTitle}-${itemIndex}`] ? <FaCheck /> : <FaClipboard />}
                  </button>
                </div>
              ) : (
                <div className="flex flex-col items-center">
                  <a 
                    href={item.link} 
                    className="text-blue-500 hover:underline text-xs md:text-sm" 
                    target="_blank" 
                    rel="noopener noreferrer"
                  >
                    Visit Resource
                  </a>
                  {item.extraLink && (
                    <a 
                      href={item.extraLink} 
                      className="text-blue-500 hover:underline text-xs md:text-sm mt-1" 
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                      {item.linkText || 'View Extra Resource'}
                    </a>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );

  return (
    <div className="flex flex-col items-center justify-center bg-gray-800 text-white p-4 pb-16">
      <h1 className="text-2xl md:text-4xl font-bold text-center text-white mb-6 flex items-center justify-center">
        <img src={SEILogo} alt="SEI Logo" className="w-8 h-8 mr-2" />
        Dev Stuff
      </h1>
      <p className="text-xl text-gray-400 text-center max-w-2xl mb-8">
        Developer code snippets, docs, and resources.
      </p>

      <div className="w-full max-w-5xl">
        {/* Render Sections */}
        {renderSection('Documentation', devDocumentationData['Documentation'])}
        {renderSection('SeiScan Queries', seiscanCodeData['SeiScan Queries'], true)} {/* Set full width for SeiScan */}
        {renderSection('Axios Specific Code', axiosCodeData['Axios Specific Code'], true)} {/* Set full width for Axios */}

        {/* Disclaimer Section */}
        <div className="mt-4 bg-gray-900 p-4 rounded-lg shadow-lg text-xs md:text-sm text-center text-gray-300">
          <p>{disclaimers.devCode}</p> {/* Use the devCode disclaimer */}
        </div>
      </div>
    </div>
  );
};

export default DevStuff;
