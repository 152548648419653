// src/components/Home.js
import React from 'react';

const Home = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-800 text-white">
      <h1 className="text-4xl font-bold mb-4 text-center">SEIGuide.xyz - Your Ultimate Guide to the Sei Blockchain</h1>
      <p className="text-xl text-gray-400 text-center max-w-2xl mb-6">
        SEIGuide.xyz is open to everyone and serves as a comprehensive resource for onboarding and educating users on the Sei blockchain. 
        Whether you're new to Sei or an experienced developer, our resources are designed to help you explore, interact, and build with ease.
      </p>
      <p className="text-lg text-gray-300 text-center max-w-xl mb-4">
        Our goal, driven by the Crafty Canines community, is to grow the Sei chain by empowering users with the tools, knowledge, and support 
        they need to become active participants in this vibrant ecosystem.
      </p>
      <p className="text-lg text-gray-300 text-center max-w-xl">
        Join us on this journey and discover all that Sei has to offer, as we work together to build a stronger, more connected blockchain community.
      </p>
    </div>
  );
};

export default Home;
