import coinbaseLogo from '../icons/Coinbase.png';
import mexcLogo from '../icons/MEXC.png';
import binanceLogo from '../icons/Binance.png';
import bybitLogo from '../icons/ByBit.png';
import bitgetLogo from '../icons/Bitget.png';
import gateioLogo from '../icons/GateIO.png';
import kucoinLogo from '../icons/Kucoin.png';
import simpleswapLogo from '../icons/SimpleSwap.png';
import krakenLogo from '../icons/Kraken.png';

export const exchangeData = [
  {
    name: "Binance",
    url: "https://www.binance.com",
    logo: binanceLogo,
    walletAddresses: [
      { 
        address: "sei1wrz9tveueyqqw6zesuae0qvh6natxumknlvvp8", 
        memoRequired: false, 
        evmSupported: false,
        link: "https://seitrace.com/address/sei1wrz9tveueyqqw6zesuae0qvh6natxumknlvvp8" 
      },
      { 
        address: "sei1zrqryvr8m40yqej95lcqzw7y0sf0662cxh0kcv", 
        memoRequired: true, 
        evmSupported: false,
        description: "Memo Required", 
        link: "https://seitrace.com/address/sei1zrqryvr8m40yqej95lcqzw7y0sf0662cxh0kcv" 
      },
    ]
  },
  {
    name: "Bitget",
    url: "https://www.bitget.com/",
    logo: bitgetLogo,
    walletAddresses: [
      { 
        address: "sei1wkew57zep2ms5czy0fr7weer5l8mn8vr769xev", 
        memoRequired: false, 
        evmSupported: true,
        link: "https://seitrace.com/address/sei1wkew57zep2ms5czy0fr7weer5l8mn8vr769xev" 
      }
    ]
  },
  {
    name: "ByBit",
    url: "https://www.bybit.com/en/",
    logo: bybitLogo,
    walletAddresses: [
      { 
        address: "sei19lx5pghv2xggm2r9yrkp4v55g7rrz9300t4ap5", 
        memoRequired: false, 
        evmSupported: false,
        link: "https://seitrace.com/address/sei19lx5pghv2xggm2r9yrkp4v55g7rrz9300t4ap5" 
      },
      { 
        address: "sei10czwjwhcjdjexgz9ecn2xma5at9t04dfaxsf4t", 
        memoRequired: true, 
        evmSupported: false,
        description: "Memo Required", 
        link: "https://seitrace.com/address/sei10czwjwhcjdjexgz9ecn2xma5at9t04dfaxsf4t" 
      },
    ]
  },
  {
    name: "Coinbase",
    url: "https://www.coinbase.com",
    logo: coinbaseLogo,
    walletAddresses: [
      { 
        address: "sei17yfw4umw6cyqmap00gcpy67gl2lzuywy9jgtjn", 
        memoRequired: false, 
        evmSupported: false,
        link: "https://seitrace.com/address/sei17yfw4umw6cyqmap00gcpy67gl2lzuywy9jgtjn" 
      },
      { 
        address: "sei1yununhk40dm5qje0mchljvey9adt876uyrzf4n", 
        memoRequired: false, 
        evmSupported: false,
        link: "https://seitrace.com/address/sei1yununhk40dm5qje0mchljvey9adt876uyrzf4n" 
      },
      { 
        address: "sei1z8jrrat54wsq6yyhsgjuurmjaxrnxjvpjn8mql", 
        memoRequired: false, 
        evmSupported: false,
        link: "https://seitrace.com/address/sei1z8jrrat54wsq6yyhsgjuurmjaxrnxjvpjn8mql" 
      }
    ]
  },
  {
    name: "Gate.io",
    url: "https://www.gate.io/",
    logo: gateioLogo,
    walletAddresses: [
      { 
        address: "sei155svs6sgxe55rnvs6ghprtqu0mh69kehwl7mxz", 
        memoRequired: false, 
        evmSupported: false,
        link: "https://seitrace.com/address/sei155svs6sgxe55rnvs6ghprtqu0mh69kehwl7mxz" 
      }
    ]
  },
  {
    name: "Kraken",
    url: "https://www.kraken.com/",
    logo: krakenLogo,
    walletAddresses: [
      { 
        address: "sei1nm0rrq86ucezaf8uj35pq9fpwr5r82cl2ufg84", 
        memoRequired: false, 
        evmSupported: false,
        link: "https://seitrace.com/address/sei1nm0rrq86ucezaf8uj35pq9fpwr5r82cl2ufg84" 
      }
    ]
  },
  {
    name: "Kucoin",
    url: "https://www.kucoin.com/",
    logo: kucoinLogo,
    walletAddresses: [
      { 
        address: "sei1hlwckhdaa26mg52gfttcm9t7qspcqx3lzylel8", 
        memoRequired: false, 
        evmSupported: false,
        link: "https://seitrace.com/address/sei1hlwckhdaa26mg52gfttcm9t7qspcqx3lzylel8" 
      },
      { 
        address: "sei1vwda8z8rpwcagd5ks7uqr3lt3cw9464hhswfe7", 
        memoRequired: true, 
        evmSupported: false,
        description: "Memo Required", 
        link: "https://seitrace.com/address/sei1vwda8z8rpwcagd5ks7uqr3lt3cw9464hhswfe7" 
      },
    ]
  },
  {
    name: "MEXC",
    url: "https://www.mexc.com",
    logo: mexcLogo,
    walletAddresses: [
      { 
        address: "sei19swk34gx92mgdkdaf06980vq9aeasypeh3cxvu", 
        memoRequired: false, 
        evmSupported: false,
        link: "https://seitrace.com/address/sei19swk34gx92mgdkdaf06980vq9aeasypeh3cxvu" 
      }
    ]
  },
  {
    name: "SimpleSwap",
    url: "https://simpleswap.io/",
    logo: simpleswapLogo,
    walletAddresses: [
      { 
        address: "sei1nzddkg7wfl8mna4kz2yyxg4um4wdpysv9qdkx0", 
        memoRequired: false, 
        evmSupported: false,
        link: "https://seitrace.com/address/sei1nzddkg7wfl8mna4kz2yyxg4um4wdpysv9qdkx0" 
      }
    ]
  }
];
