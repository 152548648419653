import stargateLogo from '../icons/Stargate.png';
import symbiosisLogo from '../icons/Symbiosis.png';

export const bridgesData = [
  {
    name: "Stargate",
    logo: stargateLogo,
    description: "Stargate is a fully composable bridge for transferring assets between blockchains, offering seamless integration with decentralized applications.",
    link: "https://stargate.finance/",
    twitter: "https://twitter.com/StargateFinance",
    docs: "https://stargateprotocol.gitbook.io/stargate/v/v2-user-docs",
    websiteLinkText: "Website"
  },
  {
    name: "Symbiosis",
    logo: symbiosisLogo,
    description: "Symbiosis is a bridge that allows users to swap assets across multiple blockchain networks seamlessly.",
    link: "https://app.symbiosis.finance/swap",
    twitter: "https://twitter.com/symbiosis_fi",
    docs: "https://docs.symbiosis.finance/",
    websiteLinkText: "Website"
  },
];
