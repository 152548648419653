import SEILogo from '../icons/SEI.png';
import DragonSwapLogo from '../icons/Dragon Swap.png';
import CompassWalletLogo from '../icons/Compass.png';
import SeiScanLogo from '../icons/SeiScan.png';
import SeiTraceLogo from '../icons/Seitrace.png';

const devDocumentationData = {
  "Documentation": [
    { name: "Compass Wallet Docs", link: "https://docs.leapwallet.io/cosmos", description: "Compass Wallet documentation for Cosmos ecosystem.", logo: CompassWalletLogo },
   { name: "DragonSwap Docs", link: "https://docs.dragonswap.app/dragonswap", description: "Documentation for Dragon Swap.", logo: DragonSwapLogo },
    { name: "Sei Docs", link: "https://www.docs.sei.io/general-overview", description: "General overview and documentation for SEI.", logo: SEILogo },
    { name: "SeiScan Docs", link: "https://docs.alleslabs.com/user-guide/introduction/overview", description: "User guide and documentation for SeiScan.", logo: SeiScanLogo },
    { name: "SeiTrace", link: "https://docs.seitrace.com/", description: "Documentation for SeiTrace.", logo: SeiTraceLogo },
  ],
};

export default devDocumentationData;
