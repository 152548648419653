// src/data/disclaimerData.js

export const disclaimers = {
  default: `
    The logos provided above are for informational purposes only. 
    I am not affiliated with, endorsed by, or officially associated with 
    any of the projects or companies represented by these logos (other than Crafty Canines). 
    All trademarks and logos are the property of their respective owners.
  `,
  spaces: `
    Disclaimer: I am not affiliated with and do not endorse any of the Twitter Spaces featured on this page. The content and views expressed in these Twitter Spaces are those of the respective hosts and participants and do not necessarily reflect my opinions or beliefs. NFA, DYOR.
  `,
  memeCoins: `
    Disclaimer: I am not affiliated with and do not endorse any of the meme coins featured on this page. The content and views expressed in these meme coins are those of the respective creators and do not necessarily reflect my opinions or beliefs. NFA, DYOR.
  `,
  nftCommunities: `
    Disclaimer: I am not affiliated with, nor do I endorse any NFT communities featured on this page. The information and content provided by these communities represent their own perspectives and do not reflect my opinions or views. Always conduct your own research before engaging with any NFT project or community.
  `,
  devCode: `
    The code examples are provided for educational purposes. Use them at your own risk.
  `,
};
