import webumpLogo from '../icons/WeBump.png';
import massdropLogo from '../icons/decoder.png';
import rhinoStakeRPCLogo from '../icons/RhinoStake.png';
import ufoLogo from '../icons/UFO.png';

export const otherToolsData = [
  {
    name: "Lighthouse NFT Minting Protocol",
    logo: webumpLogo,
    description: "Lighthouse is an open protocol and toolset developed by Webump for NFT creators and developers on the Sei blockchain.",
    link: "https://webump.xyz/",
    twitter: "https://twitter.com/webump_",
    websiteLinkText: "Website"
  },
  {
    name: "Massdrop Tools",
    logo: massdropLogo,
    description: "Massdrop provides a suite of tools for developers and users on the Sei network.",
    link: "https://www.massdrop.app/",
    twitter: "https://twitter.com/Massdrop",
    websiteLinkText: "Website"
  },
  {
    name: "Rhino Stake RPC",
    logo: rhinoStakeRPCLogo,
    description: "Rhino Stake RPC provides robust RPC services for the Sei Network, supporting both native and EVM endpoints. With geo-routed requests and a strong network of backing nodes, they ensure high performance and reliability. Additionally, they offer free private API keys as part of their comprehensive service, making it accessible for developers and projects of all sizes.",
    link: "https://rhinostake.com/",
    twitter: "https://twitter.com/RhinoStake",
    websiteLinkText: "Website"
  },
  {
    name: "Unfrgtn Discord Verification",
    logo: ufoLogo,
    description: "The ultimate verification service that brings communities together, offering seamless integration for staking, trait-based roles, and ID roles. With support for multiple wallet connections per user, it provides an inclusive and effortless experience for everyone.",
    link: "https://unfrgtn.space/",
    twitter: "https://x.com/UnfrgtnOrbit",
    websiteLinkText: "Website"
  }
];
